<template>
  <div class="card" id="print" style="border: none">
    <div class="card-body pt-4">
      <div class="main-prescription" style="min-height: 980px">
        <div class="row align-items-center">
          <div class="col-md">
            <h2>Invoice Medhop</h2>
          </div>
          <ImageComponent place="logo-default"></ImageComponent>
        </div>
        <hr class="mt-5" />

        <div class="row mt-2">
          <div class="col-md-6">
            <table>
              <tr>
                <td>Tgl. Pembayaran</td>
                <td class="px-2">:</td>
                <td>{{ data.display_paid_at }}</td>
              </tr>
              <tr>
                <td>Nama Klinik</td>
                <td class="px-2">:</td>
                <td>{{ data.tenant_name }}</td>
              </tr>
              <tr>
                <td>No. invoice</td>
                <td class="px-2">:</td>
                <td>{{ data.invoice_id }}</td>
              </tr>
            </table>
          </div>
          <div class="col-md-6">
            <table>
              <tr>
                <td>Status Pembayaran</td>
                <td class="px-2">:</td>
                <td>
                  {{ data.workstate_name == "Paid" ? "Lunas" : "Belum Lunas" }}
                </td>
              </tr>
              <tr>
                <td>Metode Pembayaran</td>
                <td class="px-2">:</td>
                <td>{{ data.payment_bank }}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <table class="table table-bordered">
              <tr>
                <th>Paket</th>
                <th>Durasi (Hari)</th>
                <th>Harga (30 Hari)</th>
                <th>Sub-Total</th>
                <th>Pajak</th>
                <th>Total</th>
              </tr>
              <tr>
                <td>{{ data.subscription_package_name.toUpperCase() }}</td>
                <td>{{ data.subscription_package_duration }}</td>
                <td>
                  {{
                    parseInt(
                      (data.subscription_package_price - data.tax_amount) /
                        (data.subscription_package_duration / 30)
                    ).toLocaleString("id-ID")
                  }}
                </td>
                <td>
                  {{
                    parseInt(
                      data.subscription_package_price - data.tax_amount
                    ).toLocaleString("id-ID")
                  }}
                </td>
                <td>{{ parseInt(data.tax_amount).toLocaleString("id-ID") }}</td>
                <td>
                  {{ parseInt(data.display_total).toLocaleString("id-ID") }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col-md-7">
            <small>
              Ket : Setelah berlangganan, tidak dapat dilakukan pengembalian
              (Refund)
            </small>
          </div>
          <div class="col-md-5">
            <table class="table table-bordered">
              <tr>
                <td>Total Dibayar</td>
                <td class="px-2">:</td>
                <td>
                  <b>{{
                    parseInt(data.display_total).toLocaleString("id-ID")
                  }}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from "@/component/general/ImageComponent.vue";

export default {
  props: {
    height: String,
    data: Object,
  },

  components: {
    ImageComponent,
  },
};
</script>

<style scoped>
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid #000;
}

.panel-body {
  background: #f1f2f7;
}

thead {
  background: transparent;
}

.bg_prescription {
  min-height: 760px;
  margin-top: 10px;
}

.prescription_footer {
  margin-bottom: 10px;
}

.bg_container {
  border: 1px solid #f1f1f1;
}

.panel {
  background: #fff;
}

.panel-body {
  background: #fff;
}

.margin_top {
  margin-top: 65px;
}

.wrapper {
  margin: 0px;
  padding: 60px 30px 0px 30px;
}

.doctor {
  color: #2f80bf;
}

.hospital {
  color: #2f80bf;
}

hr {
  border-top: 1px solid #f1f1f1;
}

.panel_button {
  margin: 10px;
}

.left_panel {
  border-right: 1px solid #ccc;
  margin-left: -15px;
}

th {
  border-bottom: 0px;
}

.col-md-4 {
  margin-right: 0px !important;
}

.patient {
  font-size: 12px;
}

.patient_name {
  font-size: 12px;
}

.prescription {
  font-size: 12px;
}

p {
  font-size: 12px;
}

.top_logo {
  margin-bottom: 10px;
}

/* @media print {

  .left_panel {
    border-right: 1px solid #ccc;
    margin-left: -15px;
  }

  .wrapper {
    margin: 0px;
    padding: 0px 10px 0px 0px;
  }

  .patient {
    width: 23%;
    float: left;
  }

  .patient_name {
    width: 31%;
    float: left;
  }

  .text-right {
    float: right;
  }

  .doctor {
    color: #2f80bf !important;
    font-size: 25px;
  }

  .hospital {
    color: #2f80bf !important;
  }

  .prescription {
    float: left;
  }

  .top_title {
    width: 70%;
  }

  .top_logo {
    width: 30%;
    margin-bottom: 10px;
  }

  .col-md-6 {
    width: 50%;
    float: left;
  }

  .col-md-5 {
    width: 45%;
    float: left;
  }

  .col-md-7 {
    width: 55%;
    float: left;
  }

  th {
    border-bottom: 0px;
  }
} */
</style>